import * as React from "react";

import Layout from "../../components/Layout";
import CodeRoll from "../../components/cards/CodeRoll";
import ContentPageHeader from "../../components/ContentPageHeader"
import HorizontalAd from "../../components/adUnits/horizontalAd"
export default class CodeIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <ContentPageHeader title="Code Snippets" />
        <section className="section">
          <div className="container">
            <div className="content">
              {/* Add search bar here and pass param through CodeRoll element */}
              <CodeRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
