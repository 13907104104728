import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import Content, { HTMLContent } from "../Content"
import HorizontalAd from "../../components/adUnits/horizontalAd"

// eslint-disable-next-line
export const CodeSnippetsTemplate = ({
  index,
  description,
  content,
  contentComponent
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className='code-snippet-preview'>
      <PostContent content={content} />
    </section>
  );
};

CodeSnippetsTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
};

class CodeRollTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    // Take in the search term param and filter on the posts prior to displaying it below

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }, index) => 
            <div className="is-parent column is-12">
                { index !== 0 && index%5 === 0 && 
                  <HorizontalAd />
                }
              <div key={index}>
                <article
                  className={`code-list-item tile is-child box ${
                    post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
                >
                  <header>
                    <Link
                      className="card-title is-size-5"
                      to={post.fields.slug}
                    >
                      <p className="post-meta">
                        {post.frontmatter.title}
                      </p>
                    </Link>
                  </header>
                  <deckgo-highlight-code language="javascript" terminal="carbon" theme="dracula">
                    <code slot="code">
                      {post.frontmatter.description.replaceAll(/(```javascript|```)/gi,'')}
                    </code>
                  </deckgo-highlight-code>
                  <Link className="button is-card-cta" to={post.fields.slug}>
                    Learn More →
                  </Link>
                </article>
              </div>
            </div>
          )}
      </div>
    )
  }
}

CodeRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function CodeRoll() {
  return (
    <StaticQuery
      query={graphql`
        query CodeRollQuery {
          allMarkdownRemark(
            sort: {order: ASC, fields: [frontmatter___title]}
            filter: {frontmatter: {templateKey: {eq: "code-snippets"}}}
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  description
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                }
                html
              }
            }
          }
        }      
      `}
      render={(data, count) => <CodeRollTemplate data={data} count={count} />}
    />
  );
}
